import { useState } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import GuaranteeLogo from 'public/assets/icons/guarantee.svg';

const ModalHeadline = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

const ModalText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  margin: `${theme.spacing(2)} 0`,
  minWidth: '250px',
  '& a': {
    fontWeight: 500,
    '&:hover': {
      opacity: '0.75',
    },
  },
}));

const Badge = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  },
}));

export const GuaranteeBadge = ({
  ...otherTooltipProps
}: Partial<TooltipProps>) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            modifiers: [
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: false,
                  tether: true,
                  rootBoundary: 'viewport',
                },
              },
            ],
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        {...otherTooltipProps}
        title={
          <div>
            <ModalHeadline variant="h6">
              Günstiger-Geht-Nicht-Garantie
            </ModalHeadline>
            <ModalText>
              Wir überweisen Ihnen eine Einmalzahlung, wenn das günstigste über
              smava.de gefundene Kreditangebot nicht günstiger sein sollte als
              ein vergleichbares, nicht über smava.de vermitteltes Kreditangebot
              einer inländischen Bank (Referenzangebot) und Sie Ihren Kredit
              trotzdem über smava.de abschließen. Durch die Einmalzahlung
              unterbieten wir das Referenzangebot.
            </ModalText>

            <ModalText>
              Mehr über die Garantie sowie alle Garantie-Bedingungen finden Sie{' '}
              <a href="https://www.smava.de/kredit/guenstiger">hier</a>.
            </ModalText>
          </div>
        }
      >
        <Badge
          src={GuaranteeLogo.src}
          onClick={() => setOpen(!open)}
          alt="Günstiger-Geht-Nicht-Garantie Badge"
        />
      </Tooltip>
    </ClickAwayListener>
  );
};
