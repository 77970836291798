import { styled } from '@mui/material/styles';
import { AverageScore } from 'lib/ekomi/types';
import Ekomi from './Ekomi';
import TuvCertificate from './TuvCertificate';

const TrustPartner = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const EkomiWrapper = styled('div')(({ theme }) => ({
  margin: `auto ${theme.spacing(2)}`
}));

export const LegalPartner = ({
  averageScore,
}: {
  averageScore?: AverageScore | null;
}) => (
  <TrustPartner>
    <TuvCertificate />
    <EkomiWrapper>{averageScore && <Ekomi {...averageScore} />}</EkomiWrapper>
  </TrustPartner>
);
